const chatData = [
  {
    id: 1,
    image: require('@/assets/images/users/avatar-2.jpg'),
    name: 'Frank Vickery',
    message: 'Hey! there I\'m available',
    time: '04    min',
    status: 'online'
  },
  {
    id: 2,
    image: require('@/assets/images/users/avatar-3.jpg'),
    name: 'Robert Winter',
    message: 'I\'ve finished it! See you so',
    time: '09 min',
    status: 'away'
  },
]

const chatMessagesData = [
  {
    name: 'Frank Vickery',
    image: require('@/assets/images/users/avatar-2.jpg'),
    message: 'Hello!',
    time: '10:00'
  },
  {
    align: 'right',
    name: 'Henry Wells',
    message: 'Hi, How are you? What about our next meeting?',
    time: '10:02'
  },
  {
    name: 'Frank Vickery',
    image: require('@/assets/images/users/avatar-2.jpg'),
    message: 'Yeah everything is fine',
    time: '10:06'
  }
]

export { chatData, chatMessagesData }
